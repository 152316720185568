import { Injectable } from '@angular/core';
// Firebase
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
// RxJS
import { first, map, take } from 'rxjs/operators';
// Auth Service
import { AuthService } from '../authentication/auth.service';

export interface Company {
    id?: string | null;
    companyName: string;
    companyDomain?: string | null;
    normalizedCompanyDomain?: string | null;
    addressLine1: string;
    addressLine2?: string;
    locationId?: string | number | null;
    city: string;
    state: string;
    postalCode: string | number;
    members?: {
        [key: string]: boolean
    } | null;
}

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    private companyCollection: AngularFirestoreCollection<Company>;

    constructor(
        private afs: AngularFirestore,
        public auth: AuthService
    ) {
        this.companyCollection = afs.collection<Company>('company')
    }

    public async getCompany(companyId: string) {
        return await this.afs.doc<any>(`company/${companyId}`)
        .snapshotChanges()
        .pipe(
            first(),
            map(snap => {
                const data = snap.payload.data(); 
                const id = snap.payload.id;
                return { id, ...data };
            })
        ).toPromise();
    }

    private async updateCompany(companyId, data) {
        const companyRef: AngularFirestoreDocument<Company> = this.afs.doc(
            `company/${companyId}`
        );
        // Update Company Data
        await companyRef.set(data, { merge: true });
        // Return updated Company object
        return await this.getCompany(companyId);
    }

    // ----------------------------------------------------------------------------------------------------
    // @ Public 
    // ----------------------------------------------------------------------------------------------------

    // Create a new company
    async create(company: Company) {
        // Save new Company to collection
        const newCompany = await this.companyCollection.add(company);
        // Resolve with Company Object and Document ID
        return await {...company, id: newCompany.id }
    }

    async addUserToCompany(companyId, userId) {
        // Create batch
        var batch = this.afs.firestore.batch();
        // Create references to data
        const userRef = this.afs.collection('users').doc(userId).ref;
        const companyRef = this.afs.collection('company').doc(companyId).ref;
        // Create batch tasks
        batch.set(userRef, {company: companyId}, { merge: true });
        batch.set(companyRef, { members: { [userId]: true } }, { merge: true });
        // Execute batch promise
        await batch.commit();
        // Return updated Company object
        return await this.getCompany(companyId);
    }

    // Check for existing domain given email
    getExistingDomainFromEmail(domain: string) {
        const normalizedDomain = domain.toLocaleLowerCase().trim().replace(/\//g, '').split('@')[1];

        return this.afs.collection('company', ref => ref.where('normalizedCompanyDomain', '==', normalizedDomain).limit(1))
            .snapshotChanges()
            .pipe(
                take(1),
                map(arr => arr.length ? { company: arr[0].payload.doc.id, ...arr[0].payload.doc.data() } : null)
            )
    }

    getMockCompany() {
        const mockCompany = {
            addressLine1: "105 SE 13th Street",
            addressLine2: null,
            city: "Deerfield Beach",
            companyDomain: "www.purplepassionfruit.com",
            companyName: "PurplePassionfruit",
            postalCode: "33441",
            state: "Florida",
            existingCompany: ""
        };

        return mockCompany;
    }

}

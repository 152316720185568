// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    firebaseConfig: {
        apiKey: "AIzaSyBi66gU8NfgPH3CH9p1qWaVzDty__WmKK0",
        authDomain: "mm-recycling.firebaseapp.com",
        databaseURL: "https://mm-recycling.firebaseio.com",
        projectId: "mm-recycling",
        storageBucket: "mm-recycling.appspot.com",
        messagingSenderId: "284976014300",
        appId: "1:284976014300:web:d2d35dc6cb74a361"
    }    
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule }    from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
// Firebase
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
// Services
import { AuthService } from './authentication/auth.service';
import { UserService } from './services/user.service';
import { CompanyService } from './services/company.service';
import { RegisterFormService } from './services/register-form.service';
import { UtilitiesService } from './services/utils.service';
import { WhiteListRequestService } from './services/whitelist-request.service';
// Angular Material
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    BrowserModule,
    // Import HttpClientModule after BrowserModule
    HttpClientModule,
    CommonModule,
    // Firebase
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    // Angular Material
    MatSnackBarModule,
  ],
  providers: [
    AuthService,
    UserService,
    CompanyService,
    RegisterFormService,
    UtilitiesService,
    WhiteListRequestService,
    { 
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, 
      useValue: {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top'
      }
    },
  ]
})
export class CoreModule { }
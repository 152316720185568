import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';

@Injectable()
export class UtilitiesService {
  getIpEndpoint = '/getIp';

  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  // -----------------------------------------------------------------------------------------------------
  // @ Public 
  // ----------------------------------------------------------------------------------------------------- 

  getClientIp() {
    return this.http.get(this.getIpEndpoint)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        map(result => result["ipAddress"]),
        catchError(this.handleError) // then handle the error
      );
  }

  assignOnlyIntersection = (sourceObj, targetObj) => {
    return Object.keys(targetObj).reduce((intersection, key) => { 
        intersection[key] = (typeof targetObj[key] === 'object') ? this.assignOnlyIntersection(sourceObj[key], targetObj[key]) : sourceObj[key] || targetObj[key];
        return intersection; 
    }, {})
  }

}
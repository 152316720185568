import { createFeatureSelector } from '@ngrx/store';
import { Injectable } from '@angular/core';
// Services
import { AuthService } from '../authentication/auth.service';
import { Roles, UserProfile } from './user.service';
import { Company, CompanyService } from './company.service';
import { UtilitiesService } from './utils.service';
import { WhiteListRequestService, WhiteListRequest } from './whitelist-request.service'
// Firebase
import * as firebase from 'firebase/app';
import 'firebase/database';

export interface UserFormData {
    id?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    passwordGroup: {
        password: string,
        passwordConfirm: string
    };
    password?: string | null;
    passwordConfirm?: string | null;
    companyTitle: string;
    phone?: string | number | null;
    licenceAgreement: {
        agree: boolean;
        signed: any;
        clientIp: string | number;
    } | boolean;
    roles: Roles;
};

export interface CompanyFormData {
    companyName: string;
    companyDomain?: string | null;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string | number;
    // Runtime parameters
    existingCompany: boolean;
}

export interface RegisterForm extends UserFormData, CompanyFormData {}

@Injectable({
    providedIn: 'root'
})
export class RegisterFormService {

    constructor(
        // Services
        public auth: AuthService,
        private companyService: CompanyService,
        private utilitiesService: UtilitiesService,
        private whitelistRequestService: WhiteListRequestService
    ) { }

    private async createNewUser(registerFormData: RegisterForm) {
        // Get current User IP address
        const clientIp = await this.utilitiesService.getClientIp().toPromise();

        // Extract User Credential data from RegisterForm
        const userData = {
            email: registerFormData.email,
            passwordGroup: registerFormData.passwordGroup,
            firstName: registerFormData.firstName,
            lastName: registerFormData.lastName,
            companyTitle: registerFormData.companyTitle,
            phone: registerFormData.phone,
            licenceAgreement: {
                agree: registerFormData.licenceAgreement,
                signed: firebase.firestore.FieldValue.serverTimestamp(),
                clientIp: clientIp
            },
            roles: {
                // Default to Pending
                pending: true
            }
        } as UserFormData;

        // Create new User from Credential
        return await this.auth.emailSignUp(userData) as UserProfile;
    }

    private async addUserToExisting(companyId, userId) {        
        // Associate User with Company
        return await this.companyService.addUserToCompany(companyId, userId)
    }

    private async createNewCompany(registerFormData: RegisterForm, user: UserProfile) {
        // Existing compan
        if (registerFormData.existingCompany) return await this.addUserToExisting(registerFormData.existingCompany, user.id);

        // Extract Cmpany from RegisterForm
        const company = {
            companyName: registerFormData.companyName,
            companyDomain: registerFormData.companyDomain,
            addressLine1:  registerFormData.addressLine1,
            addressLine2: registerFormData.addressLine2,
            city:  registerFormData.city,
            state:  registerFormData.state,
            postalCode:  registerFormData.postalCode,
            members: null
        } as Company;

        // Create new Company
        const newCompany = await this.companyService.create(company);
        // Associate User with Company
        return await this.companyService.addUserToCompany(newCompany.id, user.id);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public 
    // ----------------------------------------------------------------------------------------------------- 

    async saveFormData(formData: RegisterForm) {
        console.log("Received data:", formData);

        // Create New User
        const user = await this.createNewUser(formData);
        // Create New Company
        const company = await this.createNewCompany(formData, user);

        console.log("Company data:", company);
        console.log("User data:", user);

        this.whitelistRequestService.addRequest({...user, ...company,})

        return await {
            user: user,
            company: company
        }
    }
}
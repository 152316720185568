import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'dashboard',
        title    : 'Dashboard',
        translate: 'NAV.DASHBOARDS',
        type     : 'item',
        svgIcon  : 'monitor-dashboard',
        classes  : 'navigation-svg-icon',
        url      : '/apps/dashboards/project'
    },
    {
        id       : 'to-do',
        title    : 'Pricing Requests',
        type     : 'item',
        svgIcon  : 'calculator',
        url      : '/apps/pricing-requests',
        classes  : 'navigation-svg-icon',
        badge    : {
            title: '35',
            bg   : '#FF6F00',
            fg   : '#FFFFFF'
        }
    },
    {
        id       : 'locations',
        title    : 'Pricing Request Map',
        translate: 'NAV.PRICING_REQUEST_MAP',
        type     : 'item',
        svgIcon  : 'map-search-outline',
        classes  : 'navigation-svg-icon',
        url      : '/apps/map'
    },
    {
        id       : 'reports',
        title    : 'Report Generator',
        translate: 'NAV.REPORT_GENERATOR',
        type     : 'item',
        svgIcon  : 'file-chart',
        classes  : 'navigation-svg-icon',
        url      : '/apps/report-generator'
    },
    {
        id       : 'suppliers',
        title    : 'Suppliers',
        translate: 'NAV.CUSTOMERS',
        type     : 'item',
        svgIcon  : 'domain',
        classes  : 'navigation-svg-icon',
        url      : '/apps/suppliers'
    },
    {
        id       : 'User manager',
        title    : 'Users Manager',
        translate: 'NAV.USER_MANAGER',
        type     : 'item',
        svgIcon  : 'account-group-outline',
        classes  : 'navigation-svg-icon',
        url      : '/apps/contacts'
    },
    {
        id       : 'to-do',
        title    : 'RFP Manager',
        type     : 'item',
        svgIcon  : 'settings-outline',
        classes  : 'navigation-svg-icon',
        url      : '/apps/rfp-manager'
    },
    {
        id   : 'profile',
        title: 'Profile',
        translate: 'NAV.PROFILE',
        type : 'item',
        // icon : 'person',
        svgIcon  : 'account-circle-outline',
        classes  : 'navigation-svg-icon',
        url  : '/profile'
    }
];


import { MatSnackBar } from '@angular/material';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
// Firebase
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {
    AngularFirestore,
    AngularFirestoreDocument
} from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
// RxJS
import { Observable, of, throwError } from 'rxjs';
import { switchMap, first, map, catchError, retry, tap } from 'rxjs/operators';
// Models
import { UserFormData } from '../services/register-form.service';
import { User } from '../services/user.service';

export interface PasswordGroup {
    password: string,
    passwordConfirm: string
};

export interface Credential {
    email: string;
    password?: string;
    passwordConfirm?: string;
    passwordGroup?: PasswordGroup;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    user$: Observable<any>;
    verifyEmailEndpoint = '/verifyEmail';
    passwordResetRequestEndpoint = '/resetPassword';
    verifyPasswordResetRequestEndpoint = '/verifyResetPassword';

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router,
        private snackBar: MatSnackBar,
        private cloudFunctions: AngularFireFunctions,
        private http: HttpClient
    ) {
        this.user$ = this.afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    return this.afs.doc(`user/${user.uid}`).valueChanges();
                } else {
                    return of(null);
                }
            })
        );
    }

    private async updateUserData(userFormData: UserFormData) {
        // Remove password data
        const { passwordGroup, password, passwordConfirm, ...noPasswordData } = userFormData;
        // Create Reference to User
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
            `user/${userFormData.id}`
        );
        // Capture User Data
        const data = await userRef.set(noPasswordData);
        return await noPasswordData;
    }

    // If error, console log and notify user
    private handleError(error: Error) {
        console.error(error);
        this.snackBar.open('error.message', "Dismiss");
    }

    // determines if user has matching role
    private checkAuthorization(user: User, allowedRoles: string[]): boolean {
        if (!user) return false
        for (const role of allowedRoles) {
            if (user.roles[role]) {
                return true
            }
        }
        return false
    }

    private handleHttpError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };

    // ----------------------------------------------------------------------------------------------------
    // @ Public 
    // ----------------------------------------------------------------------------------------------------

    monitorEmailVerified() {
        // this.afAuth.auth.currentUser.reload();

        let self = this;

        let interval = setInterval(function () {
            console.log("Checking User for e-mail verified...");

            // Terminate if User logs out
            if (!self.afAuth.auth.currentUser) return clearInterval(interval);

            self.afAuth.auth.currentUser.reload();
            if (self.afAuth.auth.currentUser.emailVerified) {
                console.log("Email Verified!");
                // Clear Interval
                clearInterval(interval);
                // Navigate to Account Review
                self.router.navigate(['auth/account-review']);
                //k Exit
                return true;
            }
        }, 1000);
    }

    sentWelcomeEmail(data) {
        const callable = this.cloudFunctions.httpsCallable("sendWelcome");
        
        callable(data);
    }

    getUser() {
        return this.user$.pipe(first()).toPromise();
    }

    async isEmailVerified() {
        return await this.afAuth.auth.currentUser.emailVerified;
    }

    sendEmailVerification() {
        this.afAuth.auth.currentUser.sendEmailVerification()
        .then(() => {
            console.log("Email verification sent")
            this.snackBar.open('Email Verification Sent');
        });
    }

    signOut() {
        
        this.afAuth.auth.signOut().then(() => {
            this.snackBar.open('Signed out', "Dismiss");
            this.router.navigate(['/auth/login']);
        });
    }

    emailSignUp(userFormData: UserFormData) {
        // Extract password value
        const password = userFormData.passwordConfirm || userFormData.passwordGroup.passwordConfirm;
        // Create User with Email and Password 
        return this.afAuth.auth
            .createUserWithEmailAndPassword(userFormData.email, password)
            .then(result => {
                // Show snackbar
                this.snackBar.open('Account Created', "Dismiss");
                // Remove password data from User Data
                return this.updateUserData({ ...userFormData, id: result.user.uid }); // if using firestore
            })
            .catch(error => this.handleError(error));
    }

    login(credential: Credential) {
        return new Promise<any>((resolve, reject) => {
            // Extract password value
            const password = credential.password;
            
            // Sign In with Email and Password
            this.afAuth.auth.signInWithEmailAndPassword(credential.email, password)
            .then(result => {
                this.snackBar.open('Welcome Back!', "Dismiss");
                resolve(result);
            }, error => {
                reject(error)
            })
        })
    }

    resetPassword(credential: Credential) {
        return new Promise<any>((resolve, reject) => {
            this.sendPasswordResetRequest(credential.email)
                .then(result => {
                    this.snackBar.open('Password reset email sent', "Dismiss");
                    resolve(result);
                }, error => resolve(error))
        })
    }

    canRead(user: User): boolean {
        const allowed = ['admin', 'developer', 'companyuser', 'companyadmin']
        return this.checkAuthorization(user, allowed)
    }

    canEdit(user: User): boolean {
        const allowed = ['admin', 'editor']
        return this.checkAuthorization(user, allowed)
    }

    canDelete(user: User): boolean {
        const allowed = ['admin']
        return this.checkAuthorization(user, allowed)
    }

    sendEmailVerifyRequest(token: string) {
        console.log("Sending email verify request...");

        return this.http.post(this.verifyEmailEndpoint, JSON.stringify({ "token": token }))
            .pipe(
                retry(3), // retry a failed request up to 3 times
                map(result => result),
                catchError(this.handleHttpError) // then handle the error
            ).toPromise();
    }

    sendPasswordResetRequest(data) {
        console.log("Data in password reset:", data);

        const resetPassword = this.cloudFunctions.httpsCallable('resetPassword');

        return resetPassword(data)
        .pipe(
            retry(3), // retry a failed request up to 3 times
            map(result => result),
            tap(result => console.log("Password Reset Request:", result)),
            catchError(this.handleHttpError) // then handle the error
        ).toPromise();
    }

    sendPasswordResetConfirmation(data) {
        console.log("Password request confirmation: ****", data);

        const verifyResetPassword = this.cloudFunctions.httpsCallable('verifyResetPasswordRevised');

        return verifyResetPassword(data)
        .pipe(
            retry(3), // retry a failed request up to 3 times
            map(result => result),
            tap(result => console.log("Verify Password Reset Request:", result)),
            catchError(this.handleHttpError) // then handle the error
        ).toPromise();
    }

}
import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
// Services
import { AuthService } from 'app/core/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
// export class LoadAppsGuard implements CanActivate {
export class LoadAppsGuard implements CanLoad {

  constructor(
    public auth: AuthService,
    private router: Router
  ) {
    console.log("Load Apps guard is running...")
  }

  canLoad(
    route: Route
  ): Observable<boolean> | Promise<boolean> | boolean {

    return this.auth.user$.pipe(
      take(1),
      map(user => [user, user && this.auth.canRead(user)]),
      tap(([user, canLoadApps]) => {
        console.log("User:", user);
        console.log("Can Login:", canLoadApps);

        if (!canLoadApps) {
          // Calculate route
          const route = user && user.roles.pending ? 'auth/email-verify' : 'auth/login';
          // Navigate to route
          console.log("Calculated route (Login Guard):", route);
          this.router.navigate([route]);
        }
      }),
      map(([user, canLoadApps]) => canLoadApps)
    );
  }
}

export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARDS'  : 'Dashboards',
            'CALENDAR'    : 'Calendar',
            'ECOMMERCE'   : 'E-Commerce',
            'ACADEMY'     : 'Academy',
            'MAIL'        : {
                'TITLE': 'Mail',
                'BADGE': '25'
            },
            'MAIL_NGRX'        : {
                'TITLE': 'Mail Ngrx',
                'BADGE': '13'
            },
            'CHAT'                  : 'Chat',
            'FILE_MANAGER'          : 'File Manager',
            'COMPANY_USERS'         : 'Company Users',
            'PRICING_REQUEST_MAP'   : 'Pricing Request Map',
            'REPORT_GENERATOR'      : 'Report Generator',
            'TODO'                  : 'To-Do',
            'SCRUMBOARD'            : 'Scrumboard',
            'USER_MANAGER'          : 'User Manager',
            'CUSTOMERS'             : 'Customers',
            'SUPPLIERS'             : 'Suppliers',
            'LOCATIONS'             : 'Locations',
            'PROFILE'               : 'Profile'
        }
    }
};

import { Injectable } from '@angular/core';
// Firebase
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { firestore } from 'firebase/app';
// RxJS
import { first, map, take } from 'rxjs/operators';
// Services
import { AuthService } from '../authentication/auth.service';

export interface Roles {
    admin?: boolean;
    sales?: boolean;
    marketer?: boolean;
    developer?: boolean;
    companyuser?: boolean;
    companyadmin?: boolean;
}

export interface User {
    uid: string;
    email: string;
    displayName: string;
    roles: Roles;
    photoURL?: string; 
    phoneNumber?: string;
    emailVerified?: boolean;
}

export interface UserProfile {
    id?: string | null;
    email: string;
    firstName: string;
    lastName: string;
    company?: string | null;
    companyTitle: string;
    phone?: string | number | null;
    licenceAgreement: {
        agree: boolean;
        signed: any | null;
        clientIp: string | number | null;
    }
    created: firestore.FieldValue | null; // May also need to accept date object
    lastLogin: firestore.FieldValue | null; // May also need to accept date object
    lastAction: firestore.FieldValue | null; // May also need to accept date object
    photoURL: string | null;
    emailVerified: boolean;
    status: 'pending' | 'verified' | 'blocked' | 'active';
    roles: Roles | string[];
    // Optional parameters:
    city?: string | null;
    state?: string | null;
    postalCode?: string | null;
}

// User Profile Required properties for guard
const userProfileProperties: string[] = ['email','firstName','lastName','photoURL','emailVerified','status','roles'];

// User Profile Guard
function isUserProfile(object: any): object is UserProfile {
    for (let property in userProfileProperties) {
        // Exit if missing required property
        if (!(userProfileProperties[property] in object)) { 
            console.error(`User Profile object missing ${object[property]} property`); return false; 
        }
    }
    
    return true
}

@Injectable({
    providedIn: 'root'
})

export class UserService {
    private profileCollection: AngularFirestoreCollection<UserProfile>;

    constructor(
        private cloudFunctions: AngularFireFunctions,
        private afs: AngularFirestore,
        public auth: AuthService
    ) {
        this.profileCollection = afs.collection<UserProfile>('users')
    }

    private async getUser(userId: string) {
        return await this.afs.doc<any>(`company/${userId}`)
        .snapshotChanges()
        .pipe(
            first(),
            map(snap => {
                const data = snap.payload.data(); 
                const id = snap.payload.id;
                return { id, ...data };
            })
        ).toPromise();
    }

    private normalizeRoles(roles) {
        // Transform roles array to roles object
        if (roles.constructor === Array) {
            return this.getRoleList().reduce((acc, elem) => { acc[elem] = roles.includes(elem); return acc}, {})
        }

        return roles
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public 
    // -----------------------------------------------------------------------------------------------------

    getNewUserProfile() {
        return {
            id: '',
            firstName: '',
            lastName: '',
            company: '',
            companyTitle: '',
            email: '',
            phone: '',
            city: '',
            created: firestore.FieldValue.serverTimestamp(),
            lastLogin: null,
            lastAction: null,
            state: '',
            photoURL: 'https://storage.googleapis.com/mm-recycling.appspot.com/user-profile-default.png',
            postalCode: '',
            emailVerified: false,
            status: 'active',
            about: '',
            roles: this.normalizeRoles(["companyuser"]),
            licenceAgreement: {
                agree: false,
                signed: null,
                clientIp: null
            }
        } as UserProfile;
    }

    getRoleList(): string[] {
        return ['admin', 'sales', 'marketer', 'developer', 'companyuser', 'companyadmin']        
    }

    // Create a new User
    async create(profileData: UserProfile) {
        // Exit if not User Proile
        if (!isUserProfile(profileData)) return

        // Normalize Roles
        profileData.roles = this.normalizeRoles(profileData.roles);

        const callable = this.cloudFunctions.httpsCallable('createNewUser');

        return await callable(profileData).toPromise() as UserProfile;
    }

    async updateUserProfile(userId, data) {
        const userProfileRef: AngularFirestoreDocument<UserProfile> = this.afs.doc(
            `user/${userId}`
        );
        // Update User Data
        await userProfileRef.set(data, { merge: true });
        // Return updated User object
        return await this.getUser(userId);
    }
}

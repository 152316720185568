import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { UserProfile } from './user.service';
// RxJS
import { Observable } from 'rxjs';

export interface WhiteListRequest extends UserProfile {
  companyName: string | null;
  companyDomain: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class WhiteListRequestService {
  private whiteListRequestCollection: AngularFirestoreCollection<WhiteListRequest>;

  constructor(private afs: AngularFirestore) {
    this.whiteListRequestCollection = afs.collection<WhiteListRequest>('whitelist-request');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public 
  // -----------------------------------------------------------------------------------------------------

  async addRequest(request: WhiteListRequest) {
    return await this.whiteListRequestCollection.add(request)
  }
}
